import { transferAttributes } from "../../../../core/constants/headers"
import { SOCKET_URL } from "../../../../core/constants/urls"
import useGetTableIndex from "../../../../core/hooks/layout/useGetTableIndex"
import { formatName, formatNumber, getNames } from "../../../../core/utils/common"
import { Column, DText, Flex, Relative, Row } from "../../../../styles/CommonStyles"
import Avatar from "../../../common/utils/Avatar"
import CopyText from "../../../common/utils/CopyText"
import DateTime from "../../../common/utils/DateTime"
import UserLink from "../../../common/utils/UserLink"
import TableLayout from "../../../layout/main/TableLayout"



const TransferTable = ({ data }) => {

    const { data: tranfers } = data
    const { cs, headers } = transferAttributes
    const { getTableIndex } = useGetTableIndex()

    return (
        <TableLayout
            headers={headers}
            cs={cs}
            data={data}
        // hasWriteAccess={hasWriteAccess}
        >
            {tranfers?.data?.map((item, idx) => {

                const {
                    FULLNAME: fromName,
                    NAMEAVATAR: fromAvatar
                } = getNames(
                    item.from?.firstName,
                    item.from?.lastName
                )

                const {
                    FULLNAME: toName,
                    NAMEAVATAR: toAvatar
                } = getNames(
                    item.to?.firstName,
                    item.to?.lastName
                )

                return (
                    <Relative key={item._id}>
                        <Row cs={cs} index={idx}>
                            <Column>
                                {getTableIndex(idx)}
                            </Column>
                            <Column>
                                <Flex>
                                    <img
                                        src={
                                            item.currency === 'irt' ?
                                                require('../../../../assets/images/tooman.png') :
                                                SOCKET_URL + `assets/icon/${item.currency}.png`
                                        }
                                        alt={' '}
                                        width={'24px'}
                                    />
                                    <DText main style={{ margin: '0 8px' }}>
                                        {item.currency?.toUpperCase()}
                                    </DText>
                                </Flex>
                            </Column>
                            <Column avatar>
                                <CopyText text={item._id} justify={'flex-start'} maxWidth={30} />
                            </Column>
                            <Column avatar>
                                <Flex width='42px'>
                                    <Avatar
                                        name={fromAvatar}
                                        avatar={item.from?.avatar}
                                    />
                                </Flex>
                                <UserLink
                                    _id={item.fromId}
                                    name={formatName(fromName)}
                                />
                            </Column>
                            <Column avatar>
                                <Flex width='42px'>
                                    <Avatar
                                        name={toAvatar}
                                        avatar={item.to?.avatar}
                                    />
                                </Flex>
                                <UserLink
                                    _id={item.toId}
                                    name={formatName(toName)}
                                />
                            </Column>
                            <Column>
                                <DText primary>
                                    {formatNumber(item.amount, { type: item.currency })}
                                </DText>
                            </Column>
                            <Column>
                                <DText primary>
                                    {formatNumber(item.wage, { type: item.currency })}
                                </DText>
                            </Column>
                            <Column center>
                                <DateTime dt={item.createdAt} />
                            </Column>
                        </Row>
                    </Relative>
                )
            })}
        </TableLayout>
    )
}

export default TransferTable